<template>
  <span
    v-if="noProjects"
  >
    Geen verbonden items
  </span>

  <div class="grid grid3">
    <user-project-card
      v-for="project in filteredProjects"
      :key="project.title"
      :title="project.title"
      :sub-title="project.subTitle"
      :image="project.visualImage"
      :project-id="project.id"
      @deleted="() => onProjectDelete(project)"
    />
  </div>

  <div class="row mt-4">
    <div 
      class="col-12"
    >
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserProjectCard from "./UserProjectCard.vue"
import { removeUsersFromRestrictedProject } from '@/api/providers/projects';

export default {
  components: {
    UserProjectCard,
    MaterialButton
  },

  props: {
    projects: { type: Array, default: () => [] },
    showUpdate: { type: Boolean, default: false }
  },

  computed: {
    noProjects() {
      return this.filteredProjects.length < 1
    },

    restrictedProjects() {
      return this.projects.filter(p => p.isRestricted);
    },

    filteredProjects() {
      return this.restrictedProjects.filter(p => !p.isDeleted);
    }
  },

  methods: {
    onProjectDelete(project) {
      let projectIndex = this.restrictedProjects.findIndex(p => p === project);
      if (projectIndex < 0) return;

      this.restrictedProjects[projectIndex].isDeleted = true;
    },

    async submit(userId) {
      let deletedProjects = this.restrictedProjects.filter(p => p.isDeleted);
      for (let project of deletedProjects) {
        try {
          await removeUsersFromRestrictedProject( [ userId ], project.id);
        }
        catch { throw Error(); }
      }
    }
  }
}
</script>