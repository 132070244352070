<template>
  <div class="row mt-4">
    <material-input
      id="company-name"
      v-model="companyNameHandler"
      variant="static"
      label="Bedrijfsnaam"
      placeholder="Vul bedrijfsnaam in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="position"
      v-model="professionHandler"
      variant="static"
      label="Functie"
      placeholder="Vul functie in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-address"
      v-model="addressHandler"
      variant="static"
      label="Adres"
      placeholder="Vul straatnaam in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-zipcode"
      v-model="zipcodeHandler"
      variant="static"
      label="Postcode"
      placeholder="Vul postcode in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-city"
      v-model="cityHandler"
      variant="static"
      label="Plaats"
      placeholder="Vul de plaats in"
    />
  </div>

  <div class="row mt-4">
    <material-input
      id="company-website"
      v-model="websiteHandler"
      variant="static"
      label="Website url"
      placeholder="Vul de domeinnaam in (incl: https://)"
    />
  </div>

  <material-button
    v-if="showUpdate"
    class="float-end mt-6 mb-0"
    color="dark"
    variant="gradient"
    size="sm"
  >
    Update gegevens
  </material-button>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  name: 'CompanyInfo',

  components: {
    MaterialButton,
    MaterialInput,
  },

  props: {
    address: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    profession: {
      type: String,
      required: true,
    },
    website: {
      type: String,
      required: true,
    },
    zipcode: {
      type: String,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:address',
    'update:city',
    'update:companyName',
    'update:profession',
    'update:website',
    'update:zipcode',
  ],

  computed: {
    ...createVModelHandlers(
      'address',
      'city',
      'companyName',
      'profession',
      'website',
      'zipcode',
    ),
  },
};
</script>
