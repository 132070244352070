import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(name, claims) {
  return postRequest(
    '/roles',
    {
      name,
      isAdmin: false,
      claims,
    },
  );
}

export function getAll(includeClaims = false) {
  return getRequest(`/roles?includeClaims=${includeClaims}`);
}

export function getUsers(id) {
  return getRequest(`/roles/users/${id}`);
}

export function remove(id, newId = undefined) {
  return deleteRequest(`/roles/${id}${newId ? `?newRoleId=${newId}` : ''}`);
}

export function save(id, name, claims) {
  return putRequest(
    '/roles',
    {
      id,
      name,
      claims,
    },
  );
}
