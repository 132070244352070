import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/project', data);
}

export function edit(data) {
  return putRequest(`/project`, data)
}

export function get(id) {
  return getRequest(`/project/${id}`);
}

export function getAll() {
  return postRequest(
    '/project/1',
    {
      "currentPage": 1,
      "pageSize": 8008135,
    },
  )
    .then(({ projects }) => projects);
}

export function remove(id) {
  return deleteRequest(`/project/${id}`);
}

export function addUsersToRestrictedProject(invitedUsers, projectId) {
  return postRequest(`/project/${projectId}/users`, { invitedUsers });
}

export function removeUsersFromRestrictedProject(invitedUsers, projectId) {
  return deleteRequest(`/project/${projectId}/users`, { invitedUsers });
}

export function addGroupsToRestrictedProject(groupIds, projectId) {
  return postRequest(`/project/${projectId}/groups`, groupIds);
}

export function removeGroupFromRestrictedProject(groupId, projectId) {
  return deleteRequest(`/project/${projectId}/groups/${groupId}`);
}