<template>
  <div 
    class="row mt-4"
  >
    <div class="col-8">
      <dropdown 
        label="Bedrijf toevoegen" 
        :options="options" 
        @update:value="addCompany"
      />
    </div>
  </div>

  <div
    v-for="company in filteredCompanies" 
    :key="company.id" 
    class="row mt-2 d-flex align-items-center flex-row"
  >
    <div class="col-6 d-flex align-items-center">
      <profile-picture
        :width-and-height-in-pixels="50"
        :background-image-url="company.profilePicture"
      />
      <h6 class="ms-4">
        <router-link
          :to="`/company/${company.id}`"
        >
        {{ company.companyName }}
        </router-link>
      </h6>
    </div>
		<div class="col-6 d-flex align-items-center">
      <material-input
        class="me-2"
        placeholder="Functie"
        :model-value="company.userFunction ?? ''"
        @change="e => updateUserFunction(company, e.target.value)"
      />
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0"
        :style="{ width: '50px' }"
        @click.prevent="removeCompany(company)"
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          delete_outline
        </i>
      </button>
    </div>
	</div>
  <div class="row mt-4">
    <div 
      class="col-12"
    >
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submitUpdatedUsers"
      >
        Update gegevens
      </material-button>
    </div>
  </div>
</template>

<script>
import { getAll } from '@/api/providers/company';

import { createYesNoSweetAlert } from '../../../helpers/sweetalert';
import { addUser, removeUser, updateUser } from '@/api/providers/company';

import Dropdown from '@/components/UI/Dropdown.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import ProfilePicture from '@/components/UI/ProfilePicture.vue';

export default {
  components: {
    Dropdown,
    MaterialButton,
    MaterialInput,
    ProfilePicture
  },

  props: {
    companies: { type: Array, default: () => [] },
    showUpdate: { type: Boolean, required: true }
  },

  data() {
    return {
      options: [],
      apiCompanies: [],
      currentCompanies: undefined
    }
  },

  computed: {
    filteredCompanies() {
      return this.companyList.filter(c => !c.isDeleted);
    },

    companyList: {
      get() { 
        return this.currentCompanies !== undefined
        ? this.currentCompanies
        : this.companies;
      },
      set(value)
      {
        this.currentCompanies = value;
      }
    }
  },

  async mounted() {
    await this.getCompanies();
  },

  methods: {
    async getCompanies() {
      try {
        this.apiCompanies = await getAll();
        this.options = this.mapCompaniesToDropdownOptions(this.apiCompanies);
      }
      catch { 
        this.apiCompanies = [];
        this.options = [];
      }
    },

    mapCompaniesToDropdownOptions(companies) {
      return companies.map(c => {
        return {
          value: c.id,
          label: c.companyName,
          image: c.profilePicture
        };
      });
    },

    addCompany(companyId) {
      if (this.companyList.filter(c => c.id === companyId).length > 0) return;

      const company = this.apiCompanies.find(c => c.id === companyId);
      if(company !== undefined) {
        this.companyList.push({
          id: company.id,
          companyName: company.companyName,
          profilePicture: company.profilePicture,
          isNew: true,
        });
      }
    },

    removeCompany(company) {
      createYesNoSweetAlert(
        `Weet u zeker dat u ${company.companyName} wilt verwijderen?`,
        () => {
          let companies = this.companies;
          let index = companies.findIndex(c => c === company);
          if (index !== -1) {
            companies[index] = { ...company, isDeleted: true };
            this.companyList = companies;
          }
        }
      );
    },

    updateUserFunction(company, userFunction) {
      let companies = this.companyList;
      let index = companies.findIndex(c => c === company);
      if (index !== -1) {
        companies[index] = { ...company, userFunction, isUpdated: true };
        this.companyList = companies;
      }
    },

    async submitUpdatedCompanies(userId) {
      const filteredCompanies = this.companyList.filter(c => 
        c.isUpdated && 
        !c.isDeleted && 
        !c.isNew
      );
      
      let errorCount = 0;
      for (const company of filteredCompanies) {
        try {
          await updateUser(company.id, userId, company.canEdit, company.userFunction);
        }
        catch {
          errorCount++;
        }
      }

      return errorCount == 0;
    },

    async submitNewCompanies(userId) {
      const filteredCompanies = this.companyList.filter(c => 
        c.isNew && !c.isDeleted
      );

      let errorCount = 0;
      for (const company of filteredCompanies) {
        try {
          await addUser(company.id, userId, company.canEdit, company.userFunction);
        }
        catch {
          errorCount++;
        }
      }

      return errorCount == 0;
    },

    async submitRemovedCompanies(userId) {
      const filteredCompanies = this.companyList.filter(c => 
        c.isDeleted && !c.isNew
      );

      let errorCount = 0;
      for(const company of filteredCompanies) {
        try {
          await removeUser(company.id, userId);
        }
        catch {
          errorCount++;
        }
      }

      return errorCount == 0;
    },

    async submit(userId) {
      const addSuccessful = await this.submitNewCompanies(userId);
      const updateSuccessful = await this.submitUpdatedCompanies(userId);
      const removalSuccessful = await this.submitRemovedCompanies(userId);

      if (addSuccessful && updateSuccessful && removalSuccessful) return;

      throw new Error("Kon niet alle aanpassingen aan bedrijven opslaan.");
    },
  }
}
</script>

<style scoped>
@media(max-width: 767px) {
  .row .col-6 { width: 100%; margin-bottom: 10px; }
}
</style>