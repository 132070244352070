<template>
  <div class="form-check form-switch d-flex">
    <input
      :id="id"
      v-model="modelValueHandler"
      class="form-check-input"
      :class="$attrs.class"
      :disabled="disabled"
      type="checkbox"
      :name="name"
    />
    <label class="form-check-label ms-3" :class="labelClass" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';

export default {
  name: "MaterialSwitch",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    labelClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    ...createVModelHandlers(
      'modelValue',
    ),
  },
};
</script>

<style scoped lang="scss">
.form-check {
  margin-bottom: 0;

  &-label{
    margin-bottom: 0;
  }
}
</style>
